import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';

export default function Home() {
  return (
    <Box>
      <Typography variant="h3" component="h3" marginBottom="1rem">
        Welcome to Thrive Performance Web
      </Typography>
      <Typography variant="body" component="p">
        <RouterLink to="/login">Click here to Login</RouterLink>
      </Typography>
    </Box>
  );
}

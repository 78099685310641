import { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import PurchasableItemTypeSelect from '../../common/purchasableItemTypeSelect';
import validator from '../../../common/validations.mjs';

export default function editPurchasableItem({ item, processing, handleUpdate, handleCancel }) {
  const [name, setName] = useState(item.name);
  const [description, setDescription] = useState(item.description);
  const [price, setPrice] = useState(item.price);
  const [itemType, setItemType] = useState(item.type);
  const [quantity, setQuantity] = useState(item.quantity);
  const [duration, setDuration] = useState(item.duration);
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  function validate() {
    let valid = true;
    if (!validator().isName(name)) {
      setNameError(true);
      valid = false;
    } else {
      setNameError(false);
    }
    if (!validator().isDescription(description)) {
      setDescriptionError(true);
      valid = false;
    } else {
      setDescriptionError(false);
    }
    if (valid) {
      handleUpdate({
        itemId: item.itemId,
        name: name,
        description: description,
        price: price,
        type: itemType,
        quantity: quantity,
        duration: duration,
      });
    }
  }

  function resetForm() {
    setName(item.name);
    setDescription(item.description);
    setPrice(item.price);
    setItemType(item.type);
    setQuantity(item.quantity);
    setDuration(item.duration);
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box component="form" noValidate>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={nameError}>
          <InputLabel htmlFor="outlined-name">Name</InputLabel>
          <OutlinedInput
            id="outlined-name"
            type="text"
            value={name}
            label="Name"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <FormHelperText>{nameError && 'This name is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={descriptionError}>
          <InputLabel htmlFor="outlined-description">Description</InputLabel>
          <OutlinedInput
            id="outlined-description"
            type="text"
            value={description}
            label="Description"
            onChange={(event) => {
              setDescription(event.target.value);
            }}
          />
          <FormHelperText>{descriptionError && 'This description is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel htmlFor="outlined-price">Price</InputLabel>
          <OutlinedInput
            id="outlined-price"
            type="text"
            value={price}
            label="Price"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            onChange={(event) => {
              setPrice(parseFloat(event.target.value));
            }}
          />
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined">
          <PurchasableItemTypeSelect
            itemType={itemType}
            setItemType={setItemType}
          />
        </FormControl>
      </div>
      { (itemType === 'quantity' || itemType === 'divergence') && (
        <div>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel htmlFor="outlined-quantity">Quantity</InputLabel>
            <OutlinedInput
              id="outlined-quantity"
              type="number"
              value={quantity}
              label="Quantity"
              onChange={(event) => {
                setQuantity(event.target.value);
              }}
            />
          </FormControl>
        </div>
      )}
      { itemType === 'time' && (
        <div>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel htmlFor="outlined-quantity">Duration</InputLabel>
            <OutlinedInput
              id="outlined-duration"
              type="number"
              value={duration}
              label="Duration"
              onChange={(event) => {
                setDuration(event.target.value);
              }}
            />
          </FormControl>
        </div>
      )}
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button disabled={processing || null} variant="outlined" color="error" onClick={resetForm}>
          Cancel
        </Button>
        <Button disabled={processing || null} sx={{ ml: 1 }} variant="contained" onClick={validate}>
          Save
        </Button>
      </Box>
    </Box>
  );
}

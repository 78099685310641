import { useOutletContext, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useGetClient from '../../apis/thrivePerformance/v1/clients/getClient';
import useGetSessions from '../../apis/thrivePerformance/v1/sessions/getSessions';
import useGetWallets from '../../apis/thrivePerformance/v1/payments/getWallets';
import useGetPurchasableItems from '../../apis/thrivePerformance/v1/payments/getPurchasableItems';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SessionCard from '../../components/session/card';
import WalletCard from '../../components/payments/wallet/card';
import AddWalletItem from '../../components/payments/wallet/item/add';
import request, { addItemToWallet } from '../../apis/thrivePerformance/v1/requests.mjs';

export default function Dashboard() {
  const { siteId, clientId } = useParams();
  const [setSnackbar, loginState, setLoginState, auth, userState, setUserState, user] =
    useOutletContext();
  const [client, clientError, refreshClient] = useGetClient(auth, clientId, siteId);
  const [sessionLoading, setSessionLoading] = useState(true);
  const [sessionParams, setSessionParams] = useState({});
  const [sessions, sessionsError, refreshSessions] = useGetSessions(auth, clientId, sessionParams, siteId);
  const [sessionPage, setSessionPage] = useState(1);
  const [walletLoading, setWalletLoading] = useState(true);
  const [walletParams, setWalletParams] = useState({});
  const [wallets, walletsError, refreshWallets] = useGetWallets(auth, clientId, walletParams, siteId);
  const [walletPage, setWalletPage] = useState(1);
  const [walletId, setWalletId] = useState(null);
  const [addWalletItemDialogOpen, setAddWalletItemDialogOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [purchasableItems] = useGetPurchasableItems(auth);

  function handleSessionFilter() {
    setSessionLoading(true);
    try {
      setSessionParams({});
      refreshSessions();
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to retrieve the sessions.');
    }
    setSessionLoading(false);
  }

  function handleWalletFilter() {
    setWalletLoading(true);
    try {
      setWalletParams({});
      refreshWallets();
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to retrieve the wallets.');
    }
    setWalletLoading(false);
  }


  if (clientError) {
    setSnackbar('error', clientError.error);
  }

  useEffect(() => {
    if (sessionsError) {
      if (sessionLoading) {
        setSessionLoading(false);
      }
      setSnackbar('error', sessionsError.error);
    }
    if (walletsError) {
      if (walletLoading) {
        setWalletLoading(false);
      }
      setSnackbar('error', walletsError.error);
    }
  }, [sessionsError, walletsError]);

  const handleSessionPageChange = (event, value) => {
    setSessionParams({
      ...sessionParams,
      ...{ startFrom: (value - 1) * sessions.limit },
    });
    setSessionPage(value);
  };

  const handleWalletPageChange = (event, value) => {
    setWalletParams({
      ...walletParams,
      ...{ startFrom: (value - 1) * wallets.limit },
    });
    setWalletPage(value);
  };

  function handleOpenAddWalletItemDialog(walletId) {
    setWalletId(walletId);
    setAddWalletItemDialogOpen(true);
  }
  
  function handleDialogClose() {
    setAddWalletItemDialogOpen(false);
  }

  async function handleCreateWalletItem(item) {
    setProcessing(true);
    try {
      await request(addItemToWallet(auth, clientId, walletId, item, siteId));
      handleDialogClose();
      refreshWallets();
      setSnackbar('success', 'The wallet item was added successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to add the wallet item.');
    }
    setProcessing(false);
  }

  async function handleRecordNoShow(walletId) {
    setProcessing(true);
    setWalletId(walletId);
    try {
      await request(
        addItemToWallet(
          auth,
          clientId,
          walletId,
          {
            name: "No Show",
            description: "Client no showed the training session appointment.",
            price: 0,
            type: "quantity",
            quantity: -1,
            startDate: (new Date()).toUTCString(),
          },
          siteId
        )
      );
      handleDialogClose();
      refreshWallets();
      setSnackbar('success', 'The No Show was successfully recorded');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to record the No Show.');
    }
    setProcessing(false);
  }

  const totalSessionPages =
    sessions && sessions.totalSessions
      ? Math.floor((sessions.totalSessions - 1) / sessions.limit) + 1
      : 1;

  const totalWalletPages =
    wallets && wallets.totalWallets
      ? Math.floor((wallets.totalWallets - 1) / wallets.limit) + 1
      : 1;

  return (
    <>
      <Typography variant="h3" component="h3">
        {`Client: ${(client && client.firstName) || ''} ${(client && client.lastName) || ''}`}
      </Typography>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Typography variant="h4" component="h4">
          Sessions
        </Typography>
      </Stack>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {sessions &&
          sessions.sessions &&
          sessions.sessions.map((session) => {
            return (
              <Grid xs={12} sm={6} lg={4} key={session.sessionId}>
                <SessionCard
                  clientId={clientId}
                  session={session}
                  locale={user.locale}
                  timeZone={user.zoneinfo}
                  sx={{ pt: 2 }}
                  siteId={siteId}
                />
              </Grid>
            );
          })}
        <br />
        {!sessions && sessionLoading && (
          <Grid xs>
            <CircularProgress color="primary" />
          </Grid>
        )}
      </Grid>
      {totalSessionPages > 1 && (
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid xs>
            <Pagination
              count={totalSessionPages}
              page={sessionPage}
              onChange={handleSessionPageChange}
              size="large"
              color="primary"
            />
          </Grid>
        </Grid>
      )}
      <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
        <Typography variant="h4" component="h4">
          Wallets
        </Typography>
      </Stack>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {wallets &&
          wallets.wallets &&
          wallets.wallets.map((wallet) => {
            return (
              <Grid xs={12} sm={6} lg={4} key={wallet.walletId}>
                <WalletCard
                  clientId={clientId}
                  wallet={wallet}
                  handleAddItem={handleOpenAddWalletItemDialog}
                  handleNoShow={handleRecordNoShow}
                  locale={user.locale}
                  timeZone={user.zoneinfo}
                  sx={{ pt: 2 }}
                  siteId={siteId}
                />
              </Grid>
            );
          })}
        <br />
        {!wallets && walletLoading && (
          <Grid xs>
            <CircularProgress color="primary" />
          </Grid>
        )}
      </Grid>
      {totalWalletPages > 1 && (
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid xs>
            <Pagination
              count={totalWalletPages}
              page={walletPage}
              onChange={handleWalletPageChange}
              size="large"
              color="primary"
            />
          </Grid>
        </Grid>
      )}
      <Dialog open={addWalletItemDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Add Wallet Item</DialogTitle>
        <DialogContent>
          <AddWalletItem
            items={purchasableItems?.items}
            processing={processing}
            handleCreate={handleCreateWalletItem}
            handleCancel={handleDialogClose}
            locale={user.locale}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
